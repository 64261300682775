@import './src/Login/Resources/scss/color.scss';

$nav-horizontal-height:56px;
$nav-horizontal-height-responsive:46px;
$nav-vertical-width:17rem;
$footer-height:56px;
$media-responsive:992px;
$media-mobile:576px;
$width-100:100%;

.admin{
	.color-white{
		color:$color-white;
	}
}