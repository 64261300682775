@import './src/Admin/Resources/scss/style.scss';

.pagination{
	margin-bottom:0px;
	flex-wrap: wrap;
	li{
		margin-bottom:1rem;
	}
}

@media(max-width:$media-responsive){
	.pagination{
		display:block;
		li{
			display:inline-block;
		}
	}
}